/* eslint-env browser */
/* global $ */
$(function() {
  'use strict';
  var productSlider = $('.product__list--gallery'); 
  if (productSlider.length) {
    productSlider.slick({
      slidesToShow: 1,
      mobileFirst: true,
      responsive: [
      {
          breakpoint: 500,
          settings: {
            slidesToShow: 2
          }
        },
        {
          breakpoint: 800,
          settings: {
            slidesToShow: 3
          }
        },
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 4
          }
        },
        {
          breakpoint: 1400,
          settings: {
            slidesToShow: 5
          }
        }
      ]
    });
  };

  var MOBILE_BREAKPOINT = 1023;

  /** Check mobile resolution */
  function isMobile() {
    let scrolWidth = Math.max(document.body.scrollWidth, 
      document.documentElement.scrollWidth,
      document.body.offsetWidth,
      document.documentElement.offsetWidth,
      document.body.clientWidth,
      document.documentElement.clientWidth);
    return scrolWidth <= MOBILE_BREAKPOINT;
  }

  /**
   * Help functions
   */
  /** Resize all videos to 16/9 aspect ratio */
  function resizeVideos($videos, $target, attribute = 'width', aspectRatio = 16/9) {
    $videos.trigger('videos:resizeStart');

    if(attribute === 'width') {
      $videos.each(function () {
        var $el = $(this),
          $fluidEl = $target ? $el.closest($target) : $el.parent(),
          newWidth = $fluidEl.outerWidth();

        $el
          .width(newWidth)
          .height(Math.ceil(newWidth / aspectRatio));
      });
    } else {
      $videos.each(function () {
        var $el = $(this),
          $fluidEl = $target ? $el.closest($target) : $el.parent(),
          newHeight = $fluidEl.outerHeight();

        $el
          .height(newHeight)
          .width(Math.ceil(newHeight * aspectRatio));
      });
    }

    $videos.trigger('videos:resizeEnd');
  }



  /** Elements become visible for meauser, but hiddne( */
  function visibleForMeasure($elements) {
    $elements.css({
      position: 'absolute',
      zIndex: '-9999',
      visibility: 'hidden',
      display: 'block'
    });
  }
  function stylesPurification($elements) {
    $elements.attr('style', '');
  }

  /**
   * Videos
   */
  /** Videos resize */
  var videosResize = {};
  (function(videosResize) {
    videosResize.refresh = function() {
      var $videos = $('.video');
      var $newsPreviews = $('.news-preview .video'); // video on news previews (news)
      var $popups = $('.popup');
      var $bigPopupGalleryVideos = $('.popup-gallery--big .video'); // big gallery videos (pop-ups on simple pages)
      var $visionItems = $('.vision-item');
      var $visionVideos = $('.vision-item .video');
      var $specificVideos = $();

      $specificVideos = $specificVideos
        .add($newsPreviews)
        .add($bigPopupGalleryVideos)
        .add($visionVideos);

      resizeVideos($videos.not($specificVideos));

      $specificVideos.on('videos:resizeStart', function() {
        visibleForMeasure($visionItems);
        visibleForMeasure($popups);
      });
      $specificVideos.on('videos:resizeEnd', function() {
        stylesPurification($visionItems);
        stylesPurification($popups);
      });

      resizeVideos($visionVideos);
      resizeVideos($newsPreviews, undefined, undefined, 1.605);
      resizeVideos($bigPopupGalleryVideos, $('.popup-gallery__item'), 'height', 4/3);
    };

    $(window).on('load resize', videosResize.refresh);
  })(videosResize);

  /**
   * Message (cookie)
   */
  (function() {
    $('.message').on('click', '.message__close', function() {
      $(this).closest('.message').velocity('fadeOut', { duration: 500 });
    });
  })();

  /**
   * Header
   */
  var headerModule = {};

  (function(headerModule, productSlider) {
    var $header = $('.header');
    var $headerContent = $('.header__content');
    var $page = $header.closest('.page');
    var $headerOpenBtn = $header.find('.header__open');
    var $headerCloseBtn = $header.find('.header__close');
    var openHeaderWidth = 315;
    var initialHeaderWidth = $header.outerWidth();
    var headerContentRight = openHeaderWidth;
    var animationDuration = 600;
    var animationDelay = animationDuration / 2;

    $(window).on('load resize', function() {
      initialHeaderWidth = $header.outerWidth();
    });

    $headerOpenBtn.on('click', function(e) {
      setTimeout(function() {
        productSlider.slick('slickRemove');
      }, 0);
      if(isMobile()) {
        headerOpenMobile(e);
      } else {
        headerOpenDesktop(e);
      };
    
    });

    $headerCloseBtn.on('click', function(e) {
      setTimeout(function() {
        productSlider.slick('slickRemove');
      }, 0);

      if(isMobile()) {
        headerCloseMobile(e);
      } else {
        headerCloseDesktop(e);
      }
    });

    function headerOpenDesktop(e) {
      e.preventDefault();
      $page.addClass('open-menu');

      $headerOpenBtn.velocity(
        'transition.fadeOut',
        {duration: animationDuration - animationDelay}
      );

      $header.velocity(
        { width: openHeaderWidth },
        {
          duration: animationDuration,
          complete: function(elements) {
            $(elements).addClass('opened');
            $.fn.matchHeight._update()
          }
        }
      );

      $headerContent.velocity(
        {
          right: [0, headerContentRight],
          opacity: [1, 0]
        },
        {
          duration: animationDuration
        }
      );

      $headerCloseBtn.velocity(
        'transition.fadeIn',
        {
          duration: animationDuration - animationDelay,
          delay: animationDelay,
          easing: "ease"
        }
      );
    }

    function headerCloseDesktop(e) {
      e.preventDefault();

      $page.removeClass('open-menu');

      $headerCloseBtn.velocity(
        'transition.fadeOut',
        {
          duration: animationDuration - animationDelay
        }
      );

      $headerContent.velocity(
        {
          right: [headerContentRight, 'ease-in'],
          opacity: 0
        }, {
          duration: animationDuration
        }
      );


      $header.velocity(
        { width: initialHeaderWidth },
        {
          duration: animationDuration,
          begin: function(elements) {
            $(elements).removeClass('opened');
          },
          complete: function() {
            headerModule.headerClean();
            $.fn.matchHeight._update()
          }
        }
      );

      $headerOpenBtn.velocity(
        'transition.fadeIn',
        {
          duration: animationDuration - animationDelay,
          delay: animationDelay,
          easing: "ease"
        }
      );
    }

    function headerOpenMobile(e) {
      e.preventDefault();

      $headerOpenBtn.velocity('transition.fadeOut');
      $headerCloseBtn.velocity('transition.fadeIn');

      $headerContent.velocity('slideDown');
    }

    function headerCloseMobile(e) {
      e.preventDefault();

      $headerOpenBtn.velocity('transition.fadeIn');
      $headerCloseBtn.velocity('transition.fadeOut');

      $headerContent.velocity('slideUp');
    }

    $('.nav').on('click', '.nav__item__toggle', function() {
      var $parentItem = $(this).closest('.nav__item');

      $parentItem.toggleClass('active');

      if( $parentItem.hasClass('active') ) {
        $(this).siblings('.nav__menu').stop(true,true).slideDown();
      } else {
        $(this).siblings('.nav__menu').stop(true,true).slideUp();
      }

    });

    headerModule.headerClean = function() {
      $header.removeClass('opened');
      $header.removeAttr('style');
      $header.find('.header__open').removeAttr('style');
      $header.find('.header__close').removeAttr('style');
      $headerContent.removeAttr('style');
      $header.find('.nav__menu').removeAttr('style');
      $header.find('.nav__item').removeClass('active');
    }
  })(headerModule, productSlider);

  /**
   * Match height
   */
  (function() {
    $('.main-content__wrapper, .main-content__aside').matchHeight();
    $('.vision__column__title').matchHeight();
    $('.columns-section .col__title').matchHeight();
    $('.product__item').matchHeight();
    $('.footer__block').matchHeight();
    $('.agents__col').matchHeight();
  })();

  /**
   * Forms customization
   */
  (function() {
    jcf.setOptions('Select', {
      fakeDropInBody: true
    });
    jcf.setOptions('File', {
      placeholderText: '',
      buttonText: 'Sfoglia'
    });

    jcf.replaceAll();
  })();

 
  /**
   * User panel
   */
  (function() {
    $(window).on('load resize', function() {
      $.fn.matchHeight._update()
    });

    $('.user-panel__user').on('click', '.user__name', function(e) {
      var $userPanel = $(this).closest('.user-panel__user');
      var $userMenu = $userPanel.find('.user__menu');

      if ($userMenu.hasClass('active')) {
        $userMenu.slideUp();
        $userMenu.removeClass('active');
        $userPanel.removeClass('active');
      } else {
        $userMenu.slideDown();
        $userMenu.addClass('active');
        $userPanel.addClass('active');
      }
    });

    // function setMenuWidth() {
    //   var $userPanel = $('.user-panel__user');
    //   var $userMenu = $userPanel.find('.user__menu');
    //   var parentWidth = $userPanel.outerWidth();

    //   $userMenu.width(parentWidth);
    // }
  })();

  /**
   * Common functionality
   */
  (function() {
    var $body = $('body');

    /** Responsive breakpoint trigger */
    $(window).on('load resize', function() {
      if (isMobile() && !$body.hasClass('mobile')) {
        $body.removeClass('desktop');
        $body.addClass('mobile');

        setTimeout(headerModule.headerClean(), 0);
      } else if (!isMobile() && !$body.hasClass('desktop')) {
        $body.removeClass('mobile');
        $body.addClass('desktop');

        setTimeout(headerModule.headerClean(), 0);
      }
    });
  })();

  /**
 * Sort
 */

var sort = (function() {

  function init() {
    _setUpListeners();
  };

  function _setUpListeners() {
    var sortIcon = $('.sort__icon');
    sortIcon.on('click', sort);
  };

  function sort(e) {
    e.preventDefault();
    var $this = $(this),
        $body = $('body');

    if ($this.hasClass('sort__icon--grid') && !$body.hasClass('sort-grid')) {
        $body.removeClass('sort-row').addClass('sort-grid');
      } else if ($this.hasClass('sort__icon--row') && !$body.hasClass('sort-row')) {
        $body.removeClass('sort-grid').addClass('sort-row');
      };
  };

  return {
    init: init
  };
})();


$(document).ready(function() {
  sort.init();
  $("input, textarea").placeholder({customClass: 'custom-placeholder'});
  menuTop();
});

$('.big-slide__img-wrap').magnificPopup({
  type: 'image',
  mainClass: 'mfp-no-margins',
  image: {
    verticalFit: true
  },
  gallery: {
    enabled: true,
    navigateByImgClick: true,
    preload: [0,1]
  }
});

$('.filter-arrow').on('click', function(e) {
  e.preventDefault();
  var target = e.target;
  if ($(target).hasClass('arrow-up')) {
    $(target)
      .toggleClass('active', true)
      .siblings()
      .removeClass('active');
  } else {
    $(target)
      .toggleClass('active', true)
      .siblings()
      .removeClass('active');
  }
});

function menuTop() {
  var windowHeight = $(window).height();
  var documentHeight = $(document).height();
  var delta = documentHeight - windowHeight;
  var menuButton = $('.header__open');

  if ((delta < 230)&&(!isMobile())) {
    $(menuButton).addClass('short-page');
  };
};
});


